import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const Home = () => {
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const newPathname = `/${locale}/homePage`;
    router.push({
      pathname: newPathname
    });
  }, []);

  return <div style={{ color: 'black' }}>&nbsp;</div>;
};

export default Home;
